.search-bar__container {
    width: 50%;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    background-color: lightgray;
    padding: 15px;
    font-size: 0.75rem;
}

.search-bar__input {
    border: 1px solid black;
    border-radius: 5px;
    font-size: inherit;
    padding: 5px 10px;
    outline: none;
    width: 100%;
}