.recipe-list {
    border-right: 1px solid black;
    min-height: 100vh;
    width: 50%;
}

.recipe-list__add-recipe-button-container {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}