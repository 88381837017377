@import-normalize;
@import 'fonts.css';
@import 'buttons.css';
@import 'recipe-list.css';
@import 'recipe.css';
@import 'ingredient-list.css';
@import 'recipe-edit.css';
@import 'search-bar.css';

*, *::before, *::after {
    font-family: Gotham Rounded, sans-serif;
    box-sizing: border-box;
}

html {
    font-size: 25px;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.mr-1 {
    margin-right: 5px;
}