.btn {
    background: none;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: inherit;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
}

.btn--primary {
    color: white;
    background-color: hsl(200, 100%, 50%);
}

.btn--primary:hover {
    background-color: hsl(200, 100%, 35%);
}

.btn--danger {
    color: white;
    background-color: hsl(0, 100%, 50%);
}

.btn--danger:hover {
    background-color: hsl(0, 100%, 35%);
}